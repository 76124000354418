<div class="container text-justify bg-white p-5">

    <h1>Contacto</h1>

    <p>
        En caso de error en el portal de facturación por favor de considerar lo siguiente:
    </p>

    <h2>Libramiento Tecpan</h2>

    <p>
        Para solicitar la factura de sus boletos envíe un correo electrónico a la dirección que corresponda <b>soportetecpan@grupo-omega.com</b> incluyendo:
    </p>

    
        <ul>
            <li>
                Datos fiscales de la empresa que solicita la factura.
            </li>
            <li>
                Imagen donde se observen de manera legible los datos del boleto o boletos.
            </li>
            <li>
                La factura se enviar· al correo electrónico donde solicitó dicha factura.
            </li>
            <li>
                Para problemas con la aplicación envíanos un correo a <b>soportetecpan@grupo-omega.com</b>.
            </li>
            <li>
                Complementa el correo con fotografÌas de pantalla del error.
            </li>
        </ul>
    

    <hr>

    <p>
        En caso de error en el portal de facturación por favor de considerar lo siguiente:
    </p>

    <p>
        Para solicitar la factura de sus boletos envíe un correo electrónico a la dirección que corresponda <b>soportetecpan@grupo-omega.com</b> incluyendo:
    </p>

    
        <ul>
            <li>
                Datos fiscales de la empresa que solicita la factura.
            </li>
            <li>
                Imagen donde se observen de manera legible los datos del boleto o boletos.
            </li>
            <li>
                Imagen donde se observe de manera legible el error que presenta/que no permite continuar con el proceso de facturación.
            </li>
            <li>
                Datos de acceso para la cuenta de usuario. (previamente rellena con los datos de facturación)
            </li>
            <li>
                La factura se podra descargar desde su cuenta de usuario. 
            </li>
        </ul>
   

    <hr>

    <h2>
        ¿Problemas para crear nueva cuenta de usuario?
    </h2>

    <p>
        EnvÌa un correo con el asunto: <i>Problema de cuenta nueva</i> al correo <b>soportetecpan@grupo-omega.com</b>.
    </p>

    <p>
        www.factura-omega.com <br>
        Todos los datos son estrictamente confidenciales lo invitamos a leer nuestro aviso de privacidad en: https://grupo-omega.com
    </p>
    
</div>

<app-footer></app-footer>