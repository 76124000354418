<!-- Barra de carga -->
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="mat-elevation-z8">
    <mat-horizontal-stepper linear #stepper>
        <!-- Configuracion -->
        <mat-step>
            <ng-template matStepLabel>Configura la importación</ng-template>

            <mat-checkbox (change)="$event.checked ? form.enable() : form.disable()">
                Deseo configurar esta importación
            </mat-checkbox>
            <br><br>

            <form class="cchs-form" [formGroup]="form">

                <mat-form-field appearance="fill">
                    <mat-label>Modo de importación</mat-label>
                    <mat-select formControlName="tipo_importacion" (selectionChange)="seleccionarConfiguracion($event.value)">
                        <mat-option [value]="tipo" *ngFor="let tipo of TIPOS_IMPORTACION">
                            {{ tipo.desc }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br><br>
                <br><br>

                <mat-form-field appearance="fill">
                    <mat-label>Fila inicial</mat-label>
                    <input matInput type="number" formControlName="filaInicial">
                    <mat-hint>Los boletos serán importados a partir de la fila {{ form.get('filaInicial').value }}.</mat-hint>
                    <mat-error *ngIf="!form.get('filaInicial').valid">
                        La fila inicial debe ser mayor a cero y es necesaria.
                    </mat-error>
                </mat-form-field>
                <br><br>

                <mat-divider></mat-divider>
                <br><br>

                <mat-form-field appearance="fill">
                    <mat-label>Fecha</mat-label>
                    <mat-select formControlName="fecha">
                        <mat-option [value]="i" *ngFor="let col of COLS; let i = index">
                            {{ col }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br><br>
                <mat-form-field appearance="fill">
                    <mat-label>Hora</mat-label>
                    <mat-select formControlName="hora">
                        <mat-option [value]="i" *ngFor="let col of COLS; let i = index">
                            {{ col }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br><br>
                <mat-form-field appearance="fill">
                    <mat-label>Carril</mat-label>
                    <mat-select formControlName="carril">
                        <mat-option [value]="i" *ngFor="let col of COLS; let i = index">
                            {{ col }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br><br>
                <mat-form-field appearance="fill">
                    <mat-label>Señal</mat-label>
                    <mat-select formControlName="senal">
                        <mat-option [value]="i" *ngFor="let col of COLS; let i = index">
                            {{ col }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br><br>
                <mat-form-field appearance="fill">
                    <mat-label>Tarifa</mat-label>
                    <mat-select formControlName="tarifa">
                        <mat-option [value]="i" *ngFor="let col of COLS; let i = index">
                            {{ col }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br><br>
                <mat-form-field appearance="fill">
                    <mat-label>Pago</mat-label>
                    <mat-select formControlName="pago">
                        <mat-option [value]="i" *ngFor="let col of COLS; let i = index">
                            {{ col }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br><br>
                <mat-form-field appearance="fill">
                    <mat-label>Folio</mat-label>
                    <mat-select formControlName="folio">
                        <mat-option [value]="i" *ngFor="let col of COLS; let i = index">
                            {{ col }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

            <div class="cchs-button-toolbar">
                <button mat-flat-button class="cchs-btn-flotante" color="primary" [disabled]="loading" (click)="paso1()">
          Siguiente
        </button>
            </div>

        </mat-step>
        <!-- Seleccion de los archivos -->
        <mat-step>
            <ng-template matStepLabel>Selecciona los archivos</ng-template>
            <h2 mat-card-title>Importando: {{importConfigSelected.desc}}</h2>
            <input class="mt-3" type="file" (change)="paso2($event)" [disabled]="loading" multiple="false" />
        </mat-step>
        <!-- Importar -->
        <mat-step>
            <ng-template matStepLabel>Importar</ng-template>
            <h2 mat-card-title>Importando: {{importConfigSelected.desc}}</h2>
            <table class="table table-bordered" *ngIf="importados.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Archivo</th>
                        <th>Boletos a importar</th>
                        <th>Carril</th>
                        <th>Status de la importación</th>
                        <th>Previsualizar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let imp of importados; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ imp.file.name }}</td>
                        <td>{{ imp.data.length }}</td>
                        <td>{{ imp.carril }}</td>
                        <td>
                            <div class="alert" [ngClass]="{ 'alert-danger': imp.err, 'alert-success': !imp.err }">
                                <b *ngIf="imp.err">{{ imp.err.title }}</b> {{ imp.message }}
                            </div>
                        </td>
                        <td>
                            <button mat-icon-button color="secondary" (click)="dataPreview = imp.data.slice(0, 10)">
              <mat-icon aria-hidden="false">
                preview
              </mat-icon>
            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <generic-table *ngIf="dataPreview.length > 0" [columns]="columns" [data]="dataPreview">
            </generic-table>
            <br>
            <div class="cchs-button-toolbar">
                <button mat-raised-button *ngIf="dataPreview.length > 0" class="cchs-btn-flotante" color="secondary" (click)="dataPreview = []">
          Cerrar previsualizar
        </button>
                <button mat-flat-button class="cchs-btn-flotante" color="primary" [disabled]="loading" (click)="paso3()">
          Importar
        </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>