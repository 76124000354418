<h1>Cambio de contraseña</h1>

<form class="cchs-form" [formGroup]="form" (ngSubmit)="submit()">
  <mat-form-field appearance="fill" class="cchs-input-full">
    <mat-label>Contraseña</mat-label>
    <input matInput
           [type]="hidePassword ? 'password' : 'text'"
           [errorStateMatcher]="matcher"
           formControlName="password">
    <mat-icon class="cursor-pointer" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
    <mat-error *ngIf="!form.get('password').valid">
      La contraseña debe tener entre 8 y 10 caracteres, al menos un número, al menos una mayúscula, al menos una
      minúscula y no puede tener símbolos especiales.
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="cchs-input-full">
    <mat-label>Confirmar contraseña</mat-label>
    <input matInput
           [errorStateMatcher]="matcher"
           [type]="hidePasswordConfirm ? 'password' : 'text'"
           formControlName="confirma">
    <mat-icon class="cursor-pointer"  matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm">{{hidePasswordConfirm ? 'visibility' : 'visibility_off'}}</mat-icon>
    <mat-error *ngIf="!form.get('confirma').valid">
      Las contraseñas no coincinden.
    </mat-error>
  </mat-form-field>

  <div class="cchs-button-toolbar">
    <button mat-flat-button
            [disabled]="loading || !form.valid"
            color="primary"
            (click)="submit()">
      Cambiar contraseña
    </button>
  </div>
</form>
