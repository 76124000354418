import { Component } from '@angular/core';

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styles: [
  ]
})
export class AvisoPrivacidadComponent {
}
