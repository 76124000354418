<div class="container cchs-centrar">
    <div class="card shadow-lg o-hidden border-0 my-5">
        <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
        <div class="card-body p-0">
            <div class="row">
                <div class="col-lg-5 d-none d-lg-flex">
                    <div class="flex-grow-1 bg-register-image" style="background-image: url(&quot;assets/img/img2.jpg?h=a0a7d00bcd8e4f84f4d8ce636a8f94d4&quot;);"></div>
                </div>
                <div class="col-lg-7">
                    <div class="p-5">
                        <div class="text-center">
                            <h4 class="text-dark mb-4">¡Crea una cuenta, es gratis!</h4>
                        </div>
                        <form [formGroup]="form" (ngSubmit)="submit()" class="user">
                            <div class="form-group row">
                                <div class="col-sm-6 mb-3 mb-sm-0">
                                    <mat-form-field appearance="fill" class="cchs-input-full">
                                        <mat-label>Nombres</mat-label>
                                        <input  matInput
                                                [errorStateMatcher]="matcher"
                                                formControlName="nombres"
                                                autocomplete="off"
                                                [autofocus]="true"
                                                cdkFocusInitial>
                                        <mat-error *ngIf="!form.get('nombres').valid">
                                            El nombre o nombres debe tener una longitud mínima de 2 caracteres.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-6">
                                    <mat-form-field appearance="fill" class="cchs-input-full">
                                        <mat-label>Apellidos</mat-label>
                                        <input  matInput
                                                [errorStateMatcher]="matcher"
                                                formControlName="apellidos"
                                                autocomplete="off">
                                        <mat-error *ngIf="!form.get('apellidos').valid">
                                            Los apellidos deben tener una longitud mínima de 2 caracteres.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-group">
                                <mat-form-field appearance="fill" class="cchs-input-full">
                                    <mat-label>Correo electrónico</mat-label>
                                    <input    matInput
                                              [errorStateMatcher]="matcher"
                                              formControlName="email"
                                              autocomplete="off">
                                      <mat-error *ngIf="!form.get('email').valid">
                                          Debe ingresar una dirección de correo electrónico válida.
                                      </mat-error>
                                  </mat-form-field>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6 mb-3 mb-sm-0">
                                    <mat-form-field appearance="fill" class="cchs-input-full">
                                        <mat-label>Contraseña</mat-label>
                                        <input  matInput
                                                [errorStateMatcher]="matcher"
                                                type="password"
                                                formControlName="password">
                                        <mat-error *ngIf="!form.get('password').valid">
                                            La contraseña debe tener entre 8 y 10 caracteres, al menos un número, al menos una mayúscula, al menos una minúscula y no puede tener símbolos especiales.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-6">
                                    <mat-form-field appearance="fill" class="cchs-input-full">
                                        <mat-label>Confirmar contraseña</mat-label>
                                        <input  matInput
                                                [errorStateMatcher]="matcher"                    
                                                type="password"
                                                formControlName="confirma">
                                        <mat-error *ngIf="!form.get('confirma').valid">
                                            Las contraseñas no coincinden.
                                        </mat-error >
                                      </mat-form-field>
                                </div>
                            </div>
                            <button class="btn btn-primary btn-block text-white btn-user"
                                    type="submit"
                                    [disabled]="loading || !form.valid">
                                    Registrarse
                            </button>
                            <hr>
                        </form>
                        <div class="text-center">
                            <a class="small" routerLink="/recupera_password">
                                Olvidé mi contraseña
                            </a>
                        </div>
                        <div class="text-center">
                            <a class="small" routerLink="/login">
                                Ya tengo una cuenta
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>