<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>

<h2 mat-dialog-title>Cancelar Factura</h2>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="getFormData()">
    <mat-form-field appearance="fill" class="cchs-input-full">
      <mat-label>Motivo de cancelación</mat-label>
      <mat-select formControlName="motive"
                  (selectionChange)="onMotiveChange($event.value)"
                  [errorStateMatcher]="matcher">
        <mat-option *ngFor="let motivo of MOTIVOS_DE_CANCELACION" [value]="motivo.id">
          {{ motivo.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!form.get('motive').valid">
        El motivo de cancelación es requerido
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="cchs-input-full" *ngIf="isDocumentRelatedFieldVisible">
      <mat-label>Ingrese el folio fiscal del documento relacionado</mat-label>
      <input matInput
             placeholder="Ej. 560a8451-a29c-41d4-a716-123456789000"
             [errorStateMatcher]="matcher"
             formControlName="substitution"
             autocomplete="off">
      <mat-error *ngIf="!form.get('substitution').valid">
        Ingrese un UUID válido
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button
          mat-dialog-close
          [disabled]="loading">
    Cerrar
  </button>
  <button mat-flat-button
          [disabled]="loading || !form.valid"
          color="primary"
          [mat-dialog-close]="getFormData()">
    Cancelar factura
  </button>
</mat-dialog-actions>
