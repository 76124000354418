<mat-progress-bar mode="query" *ngIf="loading">    
</mat-progress-bar>

<h1>{{ title }}</h1>

<organism-form-tarifa
    [tarifa]="tarifa"
    [loading]="loading"
    (onCancel)="handleCancel()"
    (onSubmit)="handleSubmit($event)">    
</organism-form-tarifa>
