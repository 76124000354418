<div class="container cchs-centrar">
  <div class="row justify-content-center" *ngIf="message">
    <div class="col-md-8 col-12">
      <mat-card class="mt-3">
        <mat-card-content class="text-center">
          {{message}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-9 col-lg-12 col-xl-10">
      <div class="card shadow-lg o-hidden border-0 my-5">
        <div class="card-body p-0">
          <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
          <div class="row">
            <div class="col-lg-6 d-none d-lg-flex">
              <div class="flex-grow-1 bg-login-image"
                   style="background-image: url(&quot;assets/img/img1.jpg?h=cbc3a40dae521ddee89bf6b026abde71&quot;);"></div>
            </div>
            <div class="col-lg-6">
              <div class="p-5">
                <div class="text-center">
                  <h2 class="text-dark mb-4">Facturación Omega</h2>
                  <h4 class="text-dark mb-4">Bienvenido</h4>
                </div>
                <form [formGroup]="form" (ngSubmit)="submit()" class="user">
                  <div class="div-group">
                    <mat-form-field appearance="fill" class="cchs-input-full">
                      <mat-label>Correo electrónico</mat-label>
                      <input matInput
                             type="email"
                             formControlName="email"
                             [errorStateMatcher]="matcher"
                             autocomplete="off"
                             cdkFocusInitial>
                      <mat-icon matSuffix>face</mat-icon>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="fill" class="cchs-input-full">
                      <mat-label>Contraseña</mat-label>
                      <input matInput
                             [errorStateMatcher]="matcher"
                             [type]="hidePassword ? 'password' : 'text'"
                             formControlName="password">
                      <mat-icon class="cursor-pointer" matSuffix
                                (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </mat-form-field>
                  </div>
                  <button class="btn btn-primary btn-block text-white btn-user"
                          type="submit"
                          [disabled]="loading"
                          color="primary">
                    Entrar
                  </button>
                  <hr>
                  <div class="text-center">
                    <a class="small" routerLink="/recupera_password">
                      Olvidé mi contraseña
                    </a>
                  </div>
                  <div class="text-center">
                    <a class="small" routerLink="/registrarse">
                      Crear una cuenta
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
