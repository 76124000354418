<div class="container text-justify bg-white p-5">
    <h1>Aviso legal</h1>

    <br>

    <p>
        Aviso dirigido a los titulares de los datos personales que obran en posesión de Desarrolladora de Concesiones Omega, S.A. de C.V. y/o Libramiento Omega Tecpan S.A. de C.V. (En adelante OMEGA).
    </p>

    <p>
        Los datos personales ("Los Datos") solicitados, son tratados por OMEGA, con domicilio en avenida Cordillera de los Andes número 310, planta baja, colonia Lomas de Chapultepec, Miguel Hidalgo, C.P. 11000, México, D.F., hace de su conocimiento que los datos personales de usted, incluyendo los sensibles, que actualmente o en el futuro obren en nuestras bases de datos, serán tratados y/o utilizados por: OMEGA y/o las empresas controladoras de ésta última y/o nuestras empresas filiales y/o subsidiarias y/o aquellos terceros prestadores de servicios que, por la naturaleza de sus trabajos o funciones tengan la necesidad de tratar y/o utilizar sus datos personales; con el propósito de cumplir aquellas obligaciones que se derivan de la relación jurídica existente entre usted como titular de los datos personales y las empresas antes señaladas.
    </p>

    <p>
        En caso de formalizar con Usted la aceptación de algún servicio ofrecido por OMEGA, sus Datos serán utilizados para el cumplimiento de las obligaciones derivadas de esa relación jurídica, con la finalidad de brindarle el servicio que nos solicita sobre la petición y expedición de facturas electrónicas, atender quejas y aclaraciones, y en su caso, tratarlos para fines compatibles con los mencionados en este Aviso de Privacidad y que se consideren análogos para efectos legales.
    </p>

    <p>
        OMEGA podrá transferir los datos personales que obren en sus bases de datos a cualesquiera de las empresas controladoras de ésta última y/o a sus empresas filiales y/o subsidiarias e incluso a terceras personas, nacionales o extranjeras, salvo que los titulares respectivos manifiesten expresamente su oposición, en términos de lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la Ley).
    </p>

    <p>
        Los Datos serán tratados de conformidad con la Ley y su Reglamento. La confidencialidad de los Datos está garantizada y los mismos están protegidos por medidas de seguridad administrativas, técnicas y físicas, para evitar su daño, pérdida, alteración, destrucción, uso, acceso o divulgación indebida. Únicamente las personas autorizadas tendrán acceso a sus Datos.
    </p>

    <p>
        En caso de existir alguna modificación al presente Aviso de Privacidad se hará de su conocimiento en nuestro sitio de Internet <a href="http://www.grupo-omega.com" target="_blank">http://www.grupo-omega.com</a>.
    </p>

    <p>
        Podrá ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de sus Datos, presentando su solicitud a través del apartado correspondiente de nuestra página de internet http://www.grupo-omega.com, o por escrito dirigido al área a Grupo Omega a la dirección:
    </p>

    <address>
        Cordillera de los Andes número 310, colonia Lomas de Chapultepec, delegación Miguel Hidalgo, C.P. 11000, México, D.F., acompañando copia de su credencial del Instituto Federal Electoral y especificando los motivos de su solicitud en escrito libre.
    </address>

    <p>
        OMEGA se reserva el derecho de cambiar, modificar, complementar y/o alterar el presente aviso, en cualquier momento, en cuyo caso se hará de su conocimiento a través de cualquiera de los medios que establece la legislación en la materia.
    </p>
</div>

<app-footer></app-footer>