<div class="container cchs-centrar">
    <div class="row justify-content-center">
        <div class="col-md-9 col-lg-12 col-xl-10">
            <div class="card shadow-lg o-hidden border-0 my-5">
                <div class="card-body p-0">
                    <div class="row">
                        <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
                        <div class="col-lg-6 d-none d-lg-flex">
                            <div class="flex-grow-1 bg-password-image"
                                 style="background-image: url(&quot;assets/img/img3.jpg?h=430aabda8f7926f94f558f54049fc6e6&quot;);">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h4 class="text-dark mb-2">¿Olvidaste tu contraseña?</h4>
                                    <p class="mb-4">
                                        Te enviaremos un correo con un enlace para reestablecer tu contraseña.
                                    </p>
                                </div>
                                <form [formGroup]="form" class="user" (ngSubmit)="recuperar()">
                                    <div class="form-group">
                                        <input  class="form-control form-control-user"
                                                type="email"
                                                placeholder="Escribe tu correo electrónico..."
                                                formControlName="email">
                                    </div>
                                    <button class="btn btn-primary btn-block text-white btn-user"
                                            type="submit"
                                            [disabled]="loading || !form.valid">
                                            Reiniciar mi contraseña
                                    </button>
                                </form>
                                <div class="text-center">
                                    <hr>
                                    <a class="small" routerLink="/registrarse">
                                        Crear una cuenta
                                    </a>
                                </div>
                                <div class="text-center">
                                    <a class="small" routerLink="/login">
                                        Ya tengo una cuenta
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>