<div id="wrapper">
    <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
            <div class="container-fluid">
                <div class="text-center mt-5">
                    <div class="error mx-auto" data-text="404">
                        <p class="m-0">404</p>
                    </div>
                    <p class="text-dark mb-5 lead">Página no encontrada</p>
                    <p class="text-black-50 mb-0">
                        Estás intentando entrar a una página que no existe o que no tienes acceso.
                    </p>
                    <a href="/">← Regresar</a>
                </div>
            </div>
        </div>
    </div>    
</div>