<table  mat-table
        [dataSource]="data"
        multiTemplateDataRows
        class="mat-elevation-z8">

    <ng-container   matColumnDef="{{ column }}"
                    *ngFor="let column of columns">              
                    <th mat-header-cell *matHeaderCellDef>
                        {{ column }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element[column] | datatype }}
                    </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="columns">
    </tr>

    <tr mat-row
        *matRowDef="let element; columns: columns;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr> 
</table>
